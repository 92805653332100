import React from 'react';
import { Stack } from 'react-bootstrap';
import { BudgetSectionBackgrounds, BudgetSectionIcons, BudgetSectionType } from '../constants';
import BudgetSectionDollarAmount from './BudgetSectionDollarAmount';
import { useAppLayout } from '../contexts/AppLayoutContext';

const transition = 'all 400ms';
const segmentSize = 20;
const gap = 2;

interface IncomeVersusExpensesProps {
  income?: number;
  spending?: number;
  recurringExpenses?: number;
  recoveries?: number;
  goals?: number;
  carryover?: number;
  setExpandedBudgetSection?: (section: BudgetSectionType) => void;
}

interface Item {
  type: BudgetSectionType;
  value: number;
}

export default function IncomeVersusExpenses({
  income = 0,
  spending = 0,
  recurringExpenses = 0,
  recoveries = 0,
  goals = 0,
  carryover = 0,
  setExpandedBudgetSection,
}: IncomeVersusExpensesProps) {
  const carryoverItem: Item = { type: BudgetSectionType.Carryover, value: carryover };
  const goalsItem: Item = { type: BudgetSectionType.Goals, value: goals };
  const incomeItem: Item = { type: BudgetSectionType.Income, value: income };
  const recoveriesItem: Item = { type: BudgetSectionType.Recoveries, value: recoveries };
  const recurringExpensesItem: Item = { type: BudgetSectionType.RecurringExpenses, value: recurringExpenses };
  const spendingItem: Item = { type: BudgetSectionType.Spending, value: spending };

  // Order doesn't matter because it's going to be controlled later in render.
  // These are the positive items in terms of the UI (not the data model, where it's flipped).
  const positiveItems: Item[] = [incomeItem];
  const negativeItems: Item[] = [goalsItem, recurringExpensesItem];

  carryover > 0 ? negativeItems.push(carryoverItem) : positiveItems.push(carryoverItem);
  recoveries > 0 ? negativeItems.push(recoveriesItem) : positiveItems.push(recoveriesItem);
  spending > 0 ? negativeItems.push(spendingItem) : positiveItems.push(spendingItem);

  const positivesSum = Math.abs(positiveItems.reduce((sum, { value }) => sum + value, 0));
  const negativesSum = Math.abs(negativeItems.reduce((sum, { value }) => sum + value, 0));

  const max = Math.max(positivesSum, negativesSum);

  const positivesWidth = max === 0 ? 0 : Math.round((positivesSum / max) * 100);
  const negativesWidth = max === 0 ? 0 : Math.round((negativesSum / max) * 100);

  return (
    <div className="d-flex">
      <div style={{ flex: 1 }}>
        <Stack style={{ gap: 2 }}>
          <div style={{ width: `${positivesWidth}%`, transition, borderRadius: 10, overflow: 'hidden' }} className="d-flex">
            {[BudgetSectionType.Income, BudgetSectionType.Spending, BudgetSectionType.Carryover, BudgetSectionType.Recoveries].map(
              (budgetSectionType) => (
                <BudgetSectionSegment
                  key={budgetSectionType}
                  items={positiveItems}
                  budgetSectionType={budgetSectionType}
                  sum={positivesSum}
                  onClick={setExpandedBudgetSection}
                />
              ),
            )}
          </div>
          <div style={{ width: `${negativesWidth}%`, transition, borderRadius: 10, overflow: 'hidden' }} className="d-flex">
            {[
              BudgetSectionType.RecurringExpenses,
              BudgetSectionType.Spending,
              BudgetSectionType.Carryover,
              BudgetSectionType.Recoveries,
              BudgetSectionType.Goals,
            ].map((budgetSectionType) => (
              <BudgetSectionSegment
                key={budgetSectionType}
                items={negativeItems}
                budgetSectionType={budgetSectionType}
                sum={negativesSum}
                onClick={setExpandedBudgetSection}
              />
            ))}
          </div>
        </Stack>
      </div>
      <div className="ms-3">
        <Stack style={{ gap }} className="ms-auto">
          <div className="py-2" style={{ lineHeight: `${segmentSize}px` }}>
            <BudgetSectionDollarAmount amount={positivesSum * -1} />
          </div>
          <div className="py-2" style={{ lineHeight: `${segmentSize}px` }}>
            <BudgetSectionDollarAmount amount={negativesSum} />
          </div>
        </Stack>
      </div>
    </div>
  );
}

function BudgetSectionSegment({
  items,
  budgetSectionType,
  sum,
  onClick,
}: {
  items: Item[];
  budgetSectionType: BudgetSectionType;
  sum: number;
  onClick?: (section: BudgetSectionType) => void;
}) {
  const { isDarkMode } = useAppLayout();
  const item = items.find(({ type }) => type === budgetSectionType);
  const Icon = BudgetSectionIcons[budgetSectionType];
  const isZero = !item || item.value === 0;
  const size = segmentSize;

  return (
    <button
      style={{
        minWidth: isZero ? 0 : size,
        width: isZero ? 0 : `max(${size}px, ${Math.round(Math.abs((item.value / sum) * 1000)) / 10}%)`,
        color: BudgetSectionBackgrounds[budgetSectionType],
        backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.7)',
        opacity: isZero ? 0 : 1,
        transition,
        border: 'none',
        marginRight: isZero ? 0 : gap,
        boxSizing: 'content-box',
      }}
      className={`d-flex align-items-center justify-content-end py-2 px-${isZero ? 0 : 2}`}
      onClick={() => onClick(budgetSectionType)}
    >
      <Icon size={size} />
    </button>
  );
}
