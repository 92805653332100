// For constants that aren't shared between the front-end and back-end.

import {
  BsCalendar3,
  BsCashCoin,
  BsClipboardData,
  BsCreditCard,
  BsHourglassSplit,
  BsPiggyBank,
  BsPlusSlashMinus,
  BsShieldLockFill,
} from 'react-icons/bs';
import { IconType } from 'react-icons/lib';

enum BudgetSectionType {
  Carryover = 'Carryover',
  Goals = 'Goals',
  Income = 'Income',
  Recoveries = 'Recoveries',
  RecurringExpenses = 'RecurringExpenses',
  Runway = 'Runway',
  SafeToSpend = 'SafeToSpend',
  Spending = 'Spending',
}

const BudgetSectionLabels: Record<BudgetSectionType, string> = {
  [BudgetSectionType.Carryover]: 'Carryover',
  [BudgetSectionType.Goals]: 'Goals',
  [BudgetSectionType.Income]: 'Income',
  [BudgetSectionType.Recoveries]: 'Recoveries',
  [BudgetSectionType.RecurringExpenses]: 'Recurring Expenses',
  [BudgetSectionType.Runway]: 'Runway',
  [BudgetSectionType.SafeToSpend]: 'Safe to Spend',
  [BudgetSectionType.Spending]: 'Spending',
};

const BudgetSectionColors: Record<BudgetSectionType, string> = {
  [BudgetSectionType.Carryover]: 'var(--budget-section-carryover)',
  [BudgetSectionType.Goals]: 'var(--budget-section-goals)',
  [BudgetSectionType.Income]: 'var(--budget-section-income)',
  [BudgetSectionType.Recoveries]: 'var(--budget-section-recoveries)',
  [BudgetSectionType.RecurringExpenses]: 'var(--budget-section-recurring-expenses)',
  [BudgetSectionType.Runway]: 'var(--budget-section-runway)',
  [BudgetSectionType.SafeToSpend]: 'var(--budget-section-safe-to-spend)',
  [BudgetSectionType.Spending]: 'var(--budget-section-spending)',
};

const BudgetSectionBackgrounds: Record<BudgetSectionType, string> = {
  [BudgetSectionType.Carryover]: 'var(--budget-section-carryover-bg)',
  [BudgetSectionType.Goals]: 'var(--budget-section-goals-bg)',
  [BudgetSectionType.Income]: 'var(--budget-section-income-bg)',
  [BudgetSectionType.Recoveries]: 'var(--budget-section-recoveries-bg)',
  [BudgetSectionType.RecurringExpenses]: 'var(--budget-section-recurring-expenses-bg)',
  [BudgetSectionType.Runway]: 'var(--budget-section-runway-bg)',
  [BudgetSectionType.SafeToSpend]: 'var(--budget-section-safe-to-spend-bg)',
  [BudgetSectionType.Spending]: 'var(--budget-section-spending-bg)',
};

const BudgetSectionIcons: Record<BudgetSectionType, IconType> = {
  [BudgetSectionType.Carryover]: BsPlusSlashMinus,
  [BudgetSectionType.Goals]: BsPiggyBank,
  [BudgetSectionType.Income]: BsCashCoin,
  [BudgetSectionType.Recoveries]: BsClipboardData,
  [BudgetSectionType.RecurringExpenses]: BsCalendar3,
  [BudgetSectionType.Runway]: BsHourglassSplit,
  [BudgetSectionType.SafeToSpend]: BsShieldLockFill,
  [BudgetSectionType.Spending]: BsCreditCard,
};

export { BudgetSectionType, BudgetSectionColors, BudgetSectionBackgrounds, BudgetSectionIcons, BudgetSectionLabels };
