import React from 'react';
import formatCurrency from '../shared/formatCurrency';

interface DollarAmountProps {
  amount: number;
}

export default function BudgetSectionDollarAmount({ amount }: DollarAmountProps) {
  if (amount === undefined) return null;

  let adjustedAmount = amount;

  if (amount === -0) adjustedAmount = 0;

  const formattedAmount = formatCurrency(adjustedAmount, {
    absolute: true,
    decimals: 'never',
  });

  return <div className={`text-nowrap budget-section-dollar-amount ${amount >= 0 && 'negative'} text-end fw-bold`}>{formattedAmount}</div>;
}
