import React, { useState } from 'react';
import SectionButton from './SectionButton';
import pluralize from '../../shared/pluralize';
import { useDashboard } from '../../contexts/DashboardContext';
import RunwayModal from './RunwayModal';
import { BudgetSectionType } from '../../constants';

export default function Runway() {
  const [showRunwayModal, setShowRunwayModal] = useState(false);
  const { runway } = useDashboard();

  return (
    <>
      <SectionButton
        secondColumn={<strong>{pluralize(runway?.runwayInMonths, 'month')}</strong>}
        onClick={() => setShowRunwayModal(true)}
        budgetSectionType={BudgetSectionType.Runway}
        isLoading={!runway}
        isLast
        isFirst
      />

      <RunwayModal show={showRunwayModal} onHide={() => setShowRunwayModal(false)} />
    </>
  );
}
