import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import SafeToSpend from './SafeToSpend';
import Loader from '../Loader';
import useBudget, { UseBudgetOptions } from '../../hooks/useBudget';
import useAccounts from '../../hooks/useAccounts';
import BudgetCalendar, { Timescale } from '../../shared/BudgetCalendar';
import ErrorAlert from '../ErrorAlert';
import DashboardTimeRangeSelector from './DashboardTimeRangeSelector';
import { recalculateBudgets } from '../../firebase';
import DashboardContext from '../../contexts/DashboardContext';
import BudgetView from '../../shared/BudgetView';
import { Runway as RunwayType } from '../../types';
import IncomeAndExpenses from './IncomeAndExpenses';
import RecoveriesAndGoals from './RecoveriesAndGoals';
import Spending from './Spending';
import Carryover from './Carryover';
import useUser from '../../hooks/useUser';
import { useAppLayout } from '../../contexts/AppLayoutContext';
import IncomeVersusExpenses from '../IncomeVersusExpenses';
import { BudgetSectionType } from '../../constants';
import Runway from './Runway';

function Dashboard() {
  const { user } = useUser();
  const { timescale, budgetPeriods } = useAppLayout();
  const [isCreatingBudget, setIsCreatingBudget] = useState(false);
  const { accountsById, error: accountsError, includedAccounts, isLoading: isLoadingAccounts } = useAccounts();
  const [expandedBudgetSection, setExpandedBudgetSection] = useState<BudgetSectionType>(null);

  const useBudgetParams: UseBudgetOptions = {};

  if (timescale === Timescale.Week) useBudgetParams.weekEnd = budgetPeriods.utilization.end;
  if (timescale === Timescale.Month) useBudgetParams.monthEnd = budgetPeriods.utilization.end;

  const {
    budget,
    error: budgetError,
    isLoading: isLoadingBudget,
  } = useBudget({
    ...useBudgetParams,
    onSnapshot: (snapshot) => {
      if (
        snapshot.empty &&
        !isCreatingBudget &&
        // This may be susceptible to a bug if the user has multiple empty weeks
        // in a row. We should consider a more robust solution.
        BudgetCalendar.isNowPeriod(budgetPeriods.utilization.start, timescale)
      ) {
        setIsCreatingBudget(true);
        recalculateBudgets(budgetPeriods.utilization.start.toDate()).catch();
      } else {
        setIsCreatingBudget(false);
      }
    },
  });

  let budgetView: BudgetView;
  let runway: RunwayType;

  if (budget) {
    if (timescale === Timescale.Month) {
      budgetView = new BudgetView(
        budget.monthRecurrencePeriodIncomesSum,
        budget.monthRecurrencePeriodExpensesSum,
        budget.monthAmortizationsSum,
        budget.monthGoalsSum,
        budget.monthAllowance,
        budget.monthRunway.averageUtilization,
        budget.monthUtilization,
        budget.previousMonthRemainder,
        user?.isAllowanceBasedOnMovingAverage,
      );
      runway = budget.monthRunway;
    } else if (timescale === Timescale.Week) {
      budgetView = new BudgetView(
        budget.weekRecurrencePeriodIncomesSum / (budget.weekRecurrencePeriodLength / 7),
        budget.weekRecurrencePeriodExpensesSum / (budget.weekRecurrencePeriodLength / 7),
        budget.weekAmortizationsSum,
        budget.weekGoalsSum,
        budget.weekAllowance,
        budget.weekRunway.averageUtilization,
        budget.weekUtilization,
        budget.previousWeekRemainder,
        user?.isAllowanceBasedOnMovingAverage,
      );
      runway = budget.weekRunway;
    }
  }

  // Keep this for debugging purposes, while we roll this out and troubleshoot issues
  console.log({
    timescale,
    budgetPeriods,
    budget,
    budgetView,
  });

  return (
    <DashboardContext.Provider
      value={{
        accountsById,
        budget,
        budgetView,
        expandedBudgetSection,
        includedAccounts,
        isCreatingBudget,
        runway,
        setExpandedBudgetSection,
      }}
    >
      <Loader isLoading={isLoadingAccounts || isLoadingBudget || isCreatingBudget} />

      <ErrorAlert error={accountsError} />
      <ErrorAlert error={budgetError} />

      <Stack gap={3}>
        <section>
          <DashboardTimeRangeSelector />
        </section>

        <section>
          <SafeToSpend />
        </section>

        <section className="rounded-2">
          <Stack style={{ gap: 1 }}>
            <div
              className="p-3"
              style={{
                backgroundColor: 'var(--bs-secondary-bg)',
                borderRadius: 'var(--budget-section-border-radius) var(--budget-section-border-radius) 0 0',
              }}
            >
              <IncomeVersusExpenses
                income={budgetView?.income}
                spending={budgetView?.utilization}
                recurringExpenses={budgetView?.expenses}
                recoveries={budgetView?.recoveries}
                goals={budgetView?.goals}
                carryover={budgetView?.carryover}
                setExpandedBudgetSection={setExpandedBudgetSection}
              />
            </div>

            <IncomeAndExpenses />
            <Spending />
            <Carryover />
            <RecoveriesAndGoals />
          </Stack>
        </section>

        <section>
          <Runway />
        </section>
      </Stack>
    </DashboardContext.Provider>
  );
}

export default Dashboard;
