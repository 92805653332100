import React from 'react';
import usePlans from '../../hooks/usePlans';
import { sortBy } from 'underscore';
import PlanRow from '../Plans/PlanRow';
import { PlanDocData } from '../../types';
import { useDashboard } from '../../contexts/DashboardContext';
import SectionButton from './SectionButton';
import BetterModal from '../BetterModal';
import { useInstallments } from '../../hooks/useInstallments';
import ErrorAlert from '../ErrorAlert';
import NoContent from '../NoContent';
import NewGoalButton from '../Plans/NewGoalButton';
import dayjs from 'dayjs';
import { useAppLayout } from '../../contexts/AppLayoutContext';
import { BudgetSectionIcons, BudgetSectionLabels, BudgetSectionType } from '../../constants';
import BudgetSectionDollarAmount from '../BudgetSectionDollarAmount';

export default function RecoveriesAndGoals() {
  const { budgetPeriods, timescale } = useAppLayout();
  const { budget, budgetView, expandedBudgetSection, setExpandedBudgetSection } = useDashboard();

  const { installments: allInstallments, isLoading: isInstallmentsLoading, error: installmentsError } = useInstallments({});
  const { goals: allGoals, recoveries: allRecoveries, isLoading: isPlansLoading, error: plansError } = usePlans();

  const planIdsForPeriod = allInstallments
    .filter(
      (installment) =>
        dayjs(installment.date.toDate()).isSameOrAfter(budgetPeriods.utilization.start) &&
        dayjs(installment.date.toDate()).isBefore(budgetPeriods.utilization.end),
    )
    .map((installment) => installment.planId);
  const filterPlansToPeriod = (plan) => planIdsForPeriod.includes(plan.__id);
  const goalsForPeriod = allGoals.filter(filterPlansToPeriod);
  const completedGoals = allGoals
    .filter((plan) => !planIdsForPeriod.includes(plan.__id))
    .filter((goal) => goal.installmentsSum >= goal.targetAmount);
  const recoveries = allRecoveries.filter(filterPlansToPeriod);

  const isLoading = !budget || isPlansLoading || isInstallmentsLoading;

  return (
    <>
      <SectionButton
        secondColumn={<BudgetSectionDollarAmount amount={budgetView?.recoveries} />}
        onClick={() => setExpandedBudgetSection(BudgetSectionType.Recoveries)}
        budgetSectionType={BudgetSectionType.Recoveries}
        isLoading={isLoading}
      />

      <SectionButton
        secondColumn={<BudgetSectionDollarAmount amount={budgetView?.goals} />}
        onClick={() => setExpandedBudgetSection(BudgetSectionType.Goals)}
        budgetSectionType={BudgetSectionType.Goals}
        isLoading={isLoading}
        isLast
      />

      <BetterModal
        show={expandedBudgetSection === BudgetSectionType.Recoveries}
        title={BudgetSectionLabels[BudgetSectionType.Recoveries]}
        onHide={() => setExpandedBudgetSection(null)}
        isLoading={isLoading}
        icon={BudgetSectionIcons[BudgetSectionType.Recoveries]}
      >
        <ErrorAlert error={installmentsError || plansError} />

        {recoveries.length === 0 && (
          <NoContent>
            No recoveries affect the {timescale} of {budgetPeriods.utilization.start.format('MMM D')}.
          </NoContent>
        )}

        {recoveries.length > 0 &&
          sortBy(recoveries, (plan) => (plan.targetAmount - plan.installmentsSum) / plan.targetAmount).map((plan: PlanDocData) => (
            <PlanRow
              installmentsSum={
                recoveries
                  ? allInstallments
                      .filter((installment) => plan.__id === installment.planId)
                      .filter((installment) => dayjs(installment.date.toDate()).isBefore(budgetPeriods.utilization.end))
                      .reduce((sum, installment) => sum + installment.amount, 0)
                  : undefined
              }
              plan={plan}
              key={plan.__id}
            />
          ))}
      </BetterModal>

      <BetterModal
        show={expandedBudgetSection === BudgetSectionType.Goals}
        title={BudgetSectionLabels[BudgetSectionType.Goals]}
        onHide={() => setExpandedBudgetSection(null)}
        isLoading={isLoading}
        icon={BudgetSectionIcons[BudgetSectionType.Goals]}
      >
        <ErrorAlert error={installmentsError || plansError} />

        <NewGoalButton />

        {goalsForPeriod.length === 0 && (
          <NoContent>
            No goals affect the {timescale} of {budgetPeriods.utilization.start.format('MMM D')}.
          </NoContent>
        )}

        {goalsForPeriod.length > 0 &&
          sortBy(goalsForPeriod, (plan) => (plan.targetAmount - plan.installmentsSum) / plan.targetAmount).map((plan: PlanDocData) => (
            <PlanRow
              installmentsSum={
                goalsForPeriod
                  ? allInstallments
                      .filter((installment) => plan.__id === installment.planId)
                      .filter((installment) => dayjs(installment.date.toDate()).isBefore(budgetPeriods.utilization.end))
                      .reduce((sum, installment) => sum + installment.amount, 0)
                  : undefined
              }
              plan={plan}
              key={plan.__id}
            />
          ))}

        {completedGoals.length > 0 && completedGoals.map((plan) => <PlanRow isCompleted={true} plan={plan} key={plan.__id} />)}
      </BetterModal>
    </>
  );
}

export { usePlans };
