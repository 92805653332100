import React from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import DollarAmount from '../DollarAmount';
import SectionButton from './SectionButton';
import BetterModal from '../BetterModal';
import { useAppLayout } from '../../contexts/AppLayoutContext';
import { BudgetSectionIcons, BudgetSectionLabels, BudgetSectionType } from '../../constants';
import BudgetSectionDollarAmount from '../BudgetSectionDollarAmount';

export default function Carryover() {
  const { timescale } = useAppLayout();
  const { budgetView, expandedBudgetSection, setExpandedBudgetSection } = useDashboard();

  const isLoading = !budgetView;

  return (
    <>
      <SectionButton
        secondColumn={<BudgetSectionDollarAmount amount={budgetView?.carryover} />}
        onClick={() => setExpandedBudgetSection(BudgetSectionType.Carryover)}
        budgetSectionType={BudgetSectionType.Carryover}
        isLoading={isLoading}
      />

      <BetterModal
        show={expandedBudgetSection === BudgetSectionType.Carryover}
        onHide={() => setExpandedBudgetSection(null)}
        title={BudgetSectionLabels[BudgetSectionType.Carryover]}
        icon={BudgetSectionIcons[BudgetSectionType.Carryover]}
        isLoading={isLoading}
      >
        <p className="mb-0">
          Last {timescale} you {budgetView?.carryover > 0 ? 'overspent' : 'underspent'} by{' '}
          <DollarAmount amount={budgetView?.carryover} absolute />, which is {budgetView?.carryover > 0 ? 'subtracted from' : 'added to'}{' '}
          your Safe to Spend {timescale}.
        </p>
      </BetterModal>
    </>
  );
}
