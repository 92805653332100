import React from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import useTransactions from '../../hooks/useTransactions';
import { TransactionRows } from '../TransactionRow';
import usePlans from '../../hooks/usePlans';
import SectionButton from './SectionButton';
import BetterModal from '../BetterModal';
import ErrorAlert from '../ErrorAlert';
import NoContent from '../NoContent';
import { useAppLayout } from '../../contexts/AppLayoutContext';
import { BudgetSectionIcons, BudgetSectionLabels, BudgetSectionType } from '../../constants';
import BudgetSectionDollarAmount from '../BudgetSectionDollarAmount';

export default function Spending() {
  const { budgetPeriods } = useAppLayout();
  const { includedAccounts, accountsById, budgetView, expandedBudgetSection, setExpandedBudgetSection } = useDashboard();
  const { plansById } = usePlans();

  const {
    transactions,
    isLoading: isTransactionsLoading,
    error,
  } = useTransactions({
    startDate: budgetPeriods.utilization.start,
    endDate: budgetPeriods.utilization.end,
    accounts: includedAccounts,
  });

  const isLoading = isTransactionsLoading || !budgetView;

  return (
    <>
      <SectionButton
        secondColumn={<BudgetSectionDollarAmount amount={budgetView?.utilization} />}
        onClick={() => setExpandedBudgetSection(BudgetSectionType.Spending)}
        budgetSectionType={BudgetSectionType.Spending}
        isLoading={isLoading}
      />

      <BetterModal
        show={expandedBudgetSection === BudgetSectionType.Spending}
        title={BudgetSectionLabels[BudgetSectionType.Spending]}
        onHide={() => setExpandedBudgetSection(null)}
        isLoading={isLoading}
        icon={BudgetSectionIcons[BudgetSectionType.Spending]}
      >
        <ErrorAlert error={error} />

        {transactions.length === 0 && (
          <NoContent>
            No transactions between {budgetPeriods.utilization.start.format('MMM D')} - {budgetPeriods.utilization.end.format('MMM D')}.
          </NoContent>
        )}

        {transactions.length > 0 && (
          <TransactionRows
            transactions={transactions}
            accountsById={accountsById}
            endingBalance={budgetView?.utilization}
            plansById={plansById}
          />
        )}
      </BetterModal>
    </>
  );
}
