import React from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import formatCurrency from '../../shared/formatCurrency';
import SectionButton from './SectionButton';
import { BudgetSectionType } from '../../constants';

function SafeToSpend() {
  const { budgetView } = useDashboard();

  return (
    <SectionButton
      onClick={() => {}}
      isLoading={!budgetView}
      budgetSectionType={BudgetSectionType.SafeToSpend}
      isFirst
      isLast
      secondColumn={
        <div style={{ transition: 'color 400ms' }} className={`fw-bold text-${budgetView?.safeToSpend.color || 'muted'}`}>
          {budgetView?.safeToSpend.amountFormatted || formatCurrency(0)}
        </div>
      }
    />
  );
}

export default SafeToSpend;
