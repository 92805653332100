import React, { useState } from 'react';
import { Accordion, Form, Stack } from 'react-bootstrap';
import BetterAccordionItem from '../components/BetterAccordionItem';
import DollarAmount from '../components/DollarAmount';
import { BsBoxSeam, BsFillRocketTakeoffFill } from 'react-icons/bs';
import BetterButton from '../components/BetterButton';
import ConfettiExplosion from 'react-confetti-explosion';
import TransactionRow from '../components/TransactionRow';
import { AccountDocData, TransactionDocData } from '../types';
import { Timestamp } from 'firebase/firestore';
import ConfirmButton from '../components/ConfirmButton';
import { getLatestBudget } from '../firebase';
import IncomeVersusExpenses from '../components/IncomeVersusExpenses';
import { BudgetSectionType, BudgetSectionBackgrounds, BudgetSectionIcons } from '../constants';

export default function UserInterfacePage() {
  const [shouldThrowRenderError, setShouldThrowRenderError] = useState(false);
  const [income, setIncome] = useState(2000);
  const [spending, setSpending] = useState(0);
  const [recurringExpenses, setRecurringExpenses] = useState(-500);
  const [recoveries, setRecoveries] = useState(0);
  const [goals, setGoals] = useState(0);
  const [carryover, setCarryover] = useState(0);

  function handleButtonClick() {
    alert('Button was clicked!');
  }

  function handleThrowError() {
    throw new Error('This is a test error thrown from the UI page outside of the render path');
  }

  function getRenderErrorButtonText() {
    if (shouldThrowRenderError) throw new Error('This is a test error thrown from the UI page within the render path');

    return 'Throw error within render path';
  }

  const transaction: TransactionDocData = {
    accountId: 'account-id',
    amount: 10.0,
    category: ['Category', 'Subcategory'],
    createdAt: Timestamp.now(),
    date: Timestamp.now(),
    isGuessedCC: false,
    isIgnored: false,
    isRecurring: false,
    name: 'Transaction name',
    pending: false,
    transactionId: 'transaction-id',
    updatedAt: Timestamp.now(),
  };

  const account: AccountDocData = {
    accountId: 'account-id',
    createdAt: Timestamp.now(),
    institution: {
      institutionId: 'institution-id',
      name: 'Institution',
    },
    mask: '9999',
    name: 'Checking account',
    updatedAt: Timestamp.now(),
  };

  return (
    <Stack gap={4}>
      <section className="floating-card">
        <h3>
          <code>ConfettiExplosion</code>
        </h3>

        <div style={{ margin: '0 auto', width: 0, height: 0 }}>
          <ConfettiExplosion duration={5000} particleCount={60} />
        </div>
      </section>

      <section className="floating-card">
        <h3>
          <code>BetterAccordionItem</code>
        </h3>

        <Accordion>
          <BetterAccordionItem eventKey="0" header="Better accordion">
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="1" header="With a second line" secondLine="Here’s a little more to see">
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="2" header="With a second column" secondColumn={<DollarAmount amount={1000} />}>
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem
            eventKey="3"
            header="With a second column and a second line"
            secondColumn={<DollarAmount amount={1000} />}
            secondLine="Here’s a little more to see"
          >
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="4" header="With an icon" icon={<BsBoxSeam />}>
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem eventKey="5" header="When loading" isLoading={true}>
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem
            eventKey="6"
            header="With an error"
            error={new Error('An error occurred')}
            secondLine="Here’s a little more to see"
          >
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
          <BetterAccordionItem
            eventKey="7"
            header="The kitchen sink"
            icon={<BsBoxSeam />}
            secondLine="Here’s a little more to see"
            secondColumn={<DollarAmount amount={1000} />}
          >
            Lorem ipsum dolor sit amet
          </BetterAccordionItem>
        </Accordion>
      </section>

      <section className="floating-card">
        <h3>
          <code>BetterButton</code>
        </h3>

        <Stack gap={4}>
          <Stack direction="horizontal" gap={2}>
            <BetterButton variant="primary" onClick={handleButtonClick}>
              Primary
            </BetterButton>
            <BetterButton variant="secondary" onClick={handleButtonClick}>
              Secondary
            </BetterButton>
            <BetterButton variant="success" onClick={handleButtonClick}>
              Success
            </BetterButton>
            <BetterButton variant="warning" onClick={handleButtonClick}>
              Warning
            </BetterButton>
            <BetterButton variant="danger" onClick={handleButtonClick}>
              Danger
            </BetterButton>
            <BetterButton variant="info" onClick={handleButtonClick}>
              Info
            </BetterButton>
            <BetterButton variant="light" onClick={handleButtonClick}>
              Light
            </BetterButton>
            <BetterButton variant="dark" onClick={handleButtonClick}>
              Dark
            </BetterButton>
            <BetterButton variant="link" onClick={handleButtonClick}>
              Link
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton beforeIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Before icon
            </BetterButton>
            <BetterButton afterIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              After icon
            </BetterButton>
            <BetterButton beforeIcon={<BsFillRocketTakeoffFill />} afterIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Before & after icon
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton isLoading={true} onClick={handleButtonClick}>
              Loading
            </BetterButton>

            <BetterButton isLoading={true} beforeIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Loading before icon
            </BetterButton>

            <BetterButton isLoading={true} hideLoadingIndicators beforeIcon={<BsFillRocketTakeoffFill />} onClick={handleButtonClick}>
              Loading hide loading indicators
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton isLoading={true} loadingText="Loading text" onClick={handleButtonClick}>
              Loading text
            </BetterButton>

            <BetterButton isLoading={true} loadingText="Loading text" onClick={handleButtonClick} hideLoadingIndicators>
              Loading text with hide loading indicators
            </BetterButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <BetterButton
              isLoading={true}
              beforeIcon={<BsFillRocketTakeoffFill />}
              loadingText="Loading text"
              onClick={handleButtonClick}
              hideLoadingIndicators
            >
              Loading text with hide loading indicators and before icon
            </BetterButton>
          </Stack>
        </Stack>
      </section>

      <section>
        <h3>
          <code>TransactionRow</code>
        </h3>

        <TransactionRow
          transaction={{ ...transaction, name: 'Default' }}
          account={account}
          runningBalance={false}
          toggleRecurringOnly={false}
        />

        <TransactionRow
          transaction={{ ...transaction, name: 'Pending', pending: true }}
          account={account}
          runningBalance={false}
          toggleRecurringOnly={false}
        />

        <TransactionRow
          transaction={{ ...transaction, name: 'Really long transaction name that wraps to two lines', pending: true }}
          account={account}
          runningBalance={false}
          toggleRecurringOnly={false}
        />

        <TransactionRow
          transaction={{ ...transaction, name: 'Account missing institution' }}
          account={{ ...account, institution: undefined }}
          runningBalance={false}
          toggleRecurringOnly={false}
        />

        <TransactionRow transaction={{ ...transaction, name: 'Toggle recurring only' }} account={account} runningBalance={false} />

        <TransactionRow
          transaction={{ ...transaction, name: 'Highlight name words' }}
          account={account}
          runningBalance={false}
          toggleRecurringOnly={false}
          highlightNameWords={['nam', 'wor']}
        />
      </section>
      <section>
        <h3>
          <code>ConfirmButton</code>
        </h3>
        <Stack direction={'horizontal'} gap={2}>
          <ConfirmButton
            onConfirm={() => {
              alert('confirmed');
            }}
          >
            Confirm a thing
          </ConfirmButton>
          <ConfirmButton
            beforeIcon={<BsFillRocketTakeoffFill />}
            onConfirm={() => {
              alert('confirmed');
            }}
          >
            Confirm another thing
          </ConfirmButton>
          <ConfirmButton
            variant="success"
            onConfirm={() => {
              alert('confirmed');
            }}
          >
            Confirm a green success thing
          </ConfirmButton>
        </Stack>
      </section>
      <section>
        <h3>
          <code>getLatestBudget</code>
        </h3>
        <Stack gap={2}>
          <div>
            <BetterButton onClick={() => getLatestBudget()}>Call cloud function</BetterButton>
          </div>
          <p className="text-muted small-font">See console for details</p>
        </Stack>
      </section>
      <section>
        <h3>Error handling</h3>

        <Stack gap={2}>
          <div className="text-muted small-font">
            In development, errors will not be reported to Sentry, unless you unconditionalize the <code>Sentry.init</code> call in{' '}
            <code>App.tsx</code>. Uncaught errors outside of React’s render path will not render the <code>ErrorPage</code>.
          </div>
          <div>
            <BetterButton onClick={handleThrowError}>Throw error outside render path</BetterButton>
          </div>
          <div>
            <BetterButton onClick={() => setShouldThrowRenderError(true)}>{getRenderErrorButtonText()}</BetterButton>
          </div>
        </Stack>
      </section>
      <section>
        <h3>
          <code>IncomeVersusExpenses</code>
        </h3>
        <Stack gap={2}>
          <div className="border rounded-corner p-4">
            <IncomeVersusExpenses
              income={income === undefined ? undefined : income * -1}
              spending={spending === undefined ? undefined : spending * -1}
              recurringExpenses={recurringExpenses === undefined ? undefined : recurringExpenses * -1}
              recoveries={recoveries === undefined ? undefined : recoveries * -1}
              goals={goals === undefined ? undefined : goals * -1}
              carryover={carryover === undefined ? undefined : carryover * -1}
            />
          </div>
          <IncomeVersusExpensesSlider budgetItemType={BudgetSectionType.Income} value={income} onChange={setIncome} min={0} max={5000} />
          <IncomeVersusExpensesSlider
            budgetItemType={BudgetSectionType.Spending}
            value={spending}
            onChange={setSpending}
            min={-5000}
            max={5000}
          />
          <IncomeVersusExpensesSlider
            budgetItemType={BudgetSectionType.RecurringExpenses}
            value={recurringExpenses}
            onChange={setRecurringExpenses}
            min={-5000}
            max={0}
          />
          <IncomeVersusExpensesSlider
            budgetItemType={BudgetSectionType.Recoveries}
            value={recoveries}
            onChange={setRecoveries}
            min={-5000}
            max={5000}
          />
          <IncomeVersusExpensesSlider budgetItemType={BudgetSectionType.Goals} value={goals} onChange={setGoals} min={-5000} max={0} />
          <IncomeVersusExpensesSlider
            budgetItemType={BudgetSectionType.Carryover}
            value={carryover}
            onChange={setCarryover}
            min={-5000}
            max={5000}
          />
          <div className="text-end">
            <BetterButton
              onClick={() => {
                setIncome(2000);
                setSpending(0);
                setRecurringExpenses(-500);
                setRecoveries(0);
                setGoals(0);
                setCarryover(0);
              }}
            >
              Reset
            </BetterButton>
            <BetterButton
              onClick={() => {
                setIncome(undefined);
                setSpending(undefined);
                setRecurringExpenses(undefined);
                setRecoveries(undefined);
                setGoals(undefined);
                setCarryover(undefined);
              }}
              className="ms-2"
            >
              undefined
            </BetterButton>
          </div>
        </Stack>
      </section>
    </Stack>
  );
}

function IncomeVersusExpensesSlider({
  budgetItemType,
  value,
  onChange,
  min,
  max,
}: {
  budgetItemType: BudgetSectionType;
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
}) {
  const Icon = BudgetSectionIcons[budgetItemType];
  return (
    <div className="d-flex align-items-center">
      <Form.Label className="mb-0 me-4 w-50 px-2 py-1 rounded-2" style={{ backgroundColor: BudgetSectionBackgrounds[budgetItemType] }}>
        <Icon />
        <span className="ms-2">{budgetItemType}</span>
      </Form.Label>
      <Form.Range
        className="me-4"
        defaultValue={value}
        onMouseUp={(event) => onChange(parseInt(event.currentTarget.value))}
        step={20}
        min={min}
        max={max}
      />
      <Form.Control className="w-25" value={value} onChange={(event) => onChange(parseInt(event.target.value))} />
    </div>
  );
}
