import React from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import useTransactions from '../../hooks/useTransactions';
import { TransactionRows } from '../TransactionRow';
import isAccountChecking from '../../utils/isAccountChecking';
import RecurringExpensesByCategory from '../RecurringExpensesByCategory';
import SectionButton from './SectionButton';
import { Stack } from 'react-bootstrap';
import BetterModal from '../BetterModal';
import Tip from '../Tip';
import ErrorAlert from '../ErrorAlert';
import NoContent from '../NoContent';
import TextLink from '../TextLink';
import { ExplainerModalId } from '../../layouts/AppLayout';
import { useAppLayout } from '../../contexts/AppLayoutContext';
import { BudgetSectionIcons, BudgetSectionLabels, BudgetSectionType } from '../../constants';
import BudgetSectionDollarAmount from '../BudgetSectionDollarAmount';

export default function IncomeAndExpenses() {
  const { openExplainerModal, budgetPeriods } = useAppLayout();
  const { budget, includedAccounts, accountsById, budgetView, setExpandedBudgetSection, expandedBudgetSection } = useDashboard();

  const {
    incomes: allIncomes,
    expenses,
    error,
    isLoading: isLoadingTransactions,
  } = useTransactions({
    startDate: budgetPeriods.recurrence.start,
    endDate: budgetPeriods.recurrence.end,
    accounts: includedAccounts,
    orderByDirection: 'desc',
  });

  const incomes = allIncomes.filter((income) => isAccountChecking(accountsById[income.accountId]));
  const isLoading = !budget || isLoadingTransactions;

  return (
    <>
      <SectionButton
        secondColumn={<BudgetSectionDollarAmount amount={budgetView?.income} />}
        onClick={() => setExpandedBudgetSection(BudgetSectionType.Income)}
        budgetSectionType={BudgetSectionType.Income}
        isLoading={isLoading}
      />

      <SectionButton
        secondColumn={<BudgetSectionDollarAmount amount={budgetView?.expenses} />}
        onClick={() => setExpandedBudgetSection(BudgetSectionType.RecurringExpenses)}
        budgetSectionType={BudgetSectionType.RecurringExpenses}
        isLoading={isLoading}
      />

      <BetterModal
        show={expandedBudgetSection === BudgetSectionType.Income}
        title={BudgetSectionLabels[BudgetSectionType.Income]}
        onHide={() => setExpandedBudgetSection(null)}
        isLoading={isLoading}
        icon={BudgetSectionIcons[BudgetSectionType.Income]}
      >
        <ErrorAlert error={error} />

        {incomes.length === 0 && (
          <NoContent>
            No deposits between {budgetPeriods.recurrence.start.format('MMM D')} - {budgetPeriods.recurrence.end.format('D')}.
          </NoContent>
        )}

        {incomes.length > 0 && (
          <Stack gap={3}>
            <Tip>
              <strong>Select income from last month’s deposits</strong> like paychecks, rental income, and dividends. Avoid selecting
              one-off deposits, transfers, or paybacks.{' '}
              <TextLink onClick={() => openExplainerModal(ExplainerModalId.RecurringIncomes)}>Learn more</TextLink>
            </Tip>
            <TransactionRows transactions={incomes} accountsById={accountsById} toggleRecurringOnly />
          </Stack>
        )}
      </BetterModal>

      <BetterModal
        show={expandedBudgetSection === BudgetSectionType.RecurringExpenses}
        title={BudgetSectionLabels[BudgetSectionType.RecurringExpenses]}
        onHide={() => setExpandedBudgetSection(null)}
        isLoading={isLoading}
        icon={BudgetSectionIcons[BudgetSectionType.RecurringExpenses]}
      >
        <ErrorAlert error={error} />

        {expenses.length === 0 && (
          <NoContent>
            No expenses between {budgetPeriods.recurrence.start.format('MMM D')} - {budgetPeriods.recurrence.end.format('MMM D')}.
          </NoContent>
        )}

        {expenses.length > 0 && (
          <>
            <Tip>
              <strong>Select your essentials from last month’s expenses:</strong> rent/mortgage, insurance, utilities, gym memberships,
              child care, subscription services,{' '}
              <TextLink onClick={() => openExplainerModal(ExplainerModalId.Essentials)}>and more</TextLink>.
            </Tip>

            <RecurringExpensesByCategory transactions={expenses} accountsById={accountsById} />
          </>
        )}
      </BetterModal>
    </>
  );
}
