import React from 'react';
import { Spinner } from 'react-bootstrap';
import {} from '../IncomeVersusExpenses';
import { BudgetSectionType, BudgetSectionBackgrounds, BudgetSectionIcons, BudgetSectionLabels } from '../../constants';
import { useAppLayout } from '../../contexts/AppLayoutContext';

interface SectionButtonProps {
  secondColumn: React.ReactNode;
  onClick: () => void;
  isLoading: boolean;
  /** Adds a rounded border to the bottom corners */
  isLast?: boolean;
  /** Adds a rounded border to the top corners */
  isFirst?: boolean;
  budgetSectionType?: BudgetSectionType;
}

export default function SectionButton({
  secondColumn,
  onClick,
  budgetSectionType,
  isLoading,
  isLast = false,
  isFirst = false,
}: SectionButtonProps) {
  const { isDarkMode } = useAppLayout();

  const Icon = budgetSectionType ? BudgetSectionIcons[budgetSectionType] : undefined;

  return (
    <button
      onClick={onClick}
      className="d-flex w-100 p-3 align-items-center text-reset border-0"
      style={{
        backgroundColor: 'var(--bs-secondary-bg)',
        borderRadius: `${isFirst ? 'var(--budget-section-border-radius) var(--budget-section-border-radius)' : '0 0'} ${
          isLast ? 'var(--budget-section-border-radius) var(--budget-section-border-radius)' : '0 0'
        }`,
      }}
    >
      {Icon && (
        <div
          className="me-3 rounded-circle d-flex justify-content-center align-items-center"
          style={{
            height: 40,
            width: 40,
            color: BudgetSectionBackgrounds[budgetSectionType],
            backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.7)',
          }}
        >
          <Icon
            size={20}
            className="d-block"
            style={{ position: 'relative', top: budgetSectionType === BudgetSectionType.Income ? 2 : 0 }}
          />
        </div>
      )}
      <div className="me-auto">
        <strong>{BudgetSectionLabels[budgetSectionType]}</strong>
      </div>
      <div>{isLoading ? <Spinner size="sm" className="text-muted" /> : secondColumn}</div>
    </button>
  );
}
